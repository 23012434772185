import HxfNumberFormat from "../../../barrel/services/hxfNumberFormat";
import styles from "./TaskProgressBarV2.module.scss";
function TaskProgressBarV2(props:any) {

  const roundedVal = (num:any) => {

    // Check if the number is decimal
    if (Number.isInteger(num)) {
      return num; // If it's an integer, return as is
    } else {
        // If it's a decimal, truncate to maximum of two decimal places
        return Math.trunc(num * 100) / 100;
    }
  }

  const tasksDone = props.tasksDone;
  const totalTasks = props.totalTasks;

  const calculatePct = () => {
    if(props.showMinimumFill){

    }else{
      if (tasksDone === 0) {
        return 0;
      }
    }

    let pct = tasksDone / totalTasks;

    let minimumFill = 25;
    if(props?.minimumFillPct){
      minimumFill = props.minimumFillPct;
    }
    pct = pct * 100;
    if (pct < minimumFill) {
      return minimumFill;
    }
    if (pct > 100) {
      return 100;
    }

    return pct;
  };

  const getBarWidth = () => {
    if (props?.width) {
      return props.width;
    }

    return "155px";
  };


  const getBarHeight = () => {
    if (props?.height) {
      return props.height;
    }

    return "30px";
  };


  const getBarFillHeight = () => {
    if (props?.height) {
      return props.height - 1;
    }

    return "29px";
  };

  const getFontSize = () => {

    if(props.fontSize){
      return props.fontSize;
    }
    return "15px"
  }

  const getFillerRadius = () => {
    if(props.fillerRadius){
      return props.fillerRadius;
    }

    return "25px";
  }
  const getProgressBarContainerWidth = () => {

    if(props.progressBarContainerWidth){
      return props.progressBarContainerWidth;
    }
    return "100%";
  }

  const getFillerColor = () => {

  
    if(props.fillerColor){
      return props.fillerColor;
    }
    return "rgb(43 37 141 / 25%)";
  }
  return (
   
    <div
    data-tasks-done={tasksDone}
    data-total-tasks={totalTasks}
    className={styles.progressBarContainer} style={{width:getProgressBarContainerWidth()}}>
      <div
        className={styles.elemElementDescription}
        style={{ width: getBarWidth(), height:getBarHeight(), fontSize:getFontSize(), borderColor:props?.borderColor ? props.borderColor : 'black' }}
      >
        <div className={styles.barLabel} style={{width:getBarWidth()}}>
          <p>
            {HxfNumberFormat().val(tasksDone)}/{HxfNumberFormat().val(totalTasks)}
            {/* roundedVal(tasksDone)}/{roundedVal(totalTasks) */}{" "}
            {props?.totalTasksNumberLabel ? props.totalTasksNumberLabel : ""}
          </p>
        </div>
        <div
          className={styles.fillerBar}
          style={{ top:"-" + getBarHeight(), width: calculatePct() + "%", height:getBarFillHeight(), borderRadius:getFillerRadius(), backgroundColor:getFillerColor()}}
        >
          {" "}
          <div className={styles.fillerBarContent}> </div>
        </div>

        {/*
          <div className={styles.divPgBContainer}>
            <div
              className={styles.fillerBar}
              style={{ width: calculatePct() + "%", height:getBarFillHeight(), borderRadius:getFillerRadius(), backgroundColor:getFillerColor()}}
            >
              {" "}
              <div className={styles.fillerBarContent}> </div>
            </div>
          </div>
        */}
      </div>
    </div>
   
  );
}

export default TaskProgressBarV2;
