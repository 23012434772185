import { IonButton, IonIcon, useIonAlert } from "@ionic/react";
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import { Paper, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { arrowUndoCircle, checkmarkDoneSharp, closeOutline, lockOpenOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import inventoryEvaluationOrderProductsController from "../../../../../barrel/controllers/inventoryEvaluationOrderProducts";
import inventoryEvaluationOrderRecordsController from "../../../../../barrel/controllers/inventoryEvaluationOrderRecordsController";

import useFeedbackService from "../../../../../barrel/hooks/useFeedbackService";
import useHxfTranslation from "../../../../../barrel/hooks/useHxfTranslation";
import useLocalStorageHandler from "../../../../../barrel/hooks/useLocalStorageHandler";
import DatesConversionService from "../../../../../barrel/services/datesConversionService";
import HxfNumberFormat from "../../../../../barrel/services/hxfNumberFormat";
import MathsService from "../../../../../barrel/services/mathsservice";
import HxfOverlayLoading from "../../../../CustomElements/HxfOverlayLoading/HxfOverlayLoading";
import HxfIonPopup from "../../../../CustomElements/ProductionUI/HxfIonPopup/HxfIonPopup";
import styles from "./IEStockCountingProductPopup.module.scss";

interface IEStockEvaluatingProduct{
    Id_Product:any;
    arrayProductParameterizedVariables?:any;
    name:any;
    code:any;
    Id_WarehouseLocation?:any;
    Id_Warehouse?:any;
    Id_Lot?:any
    warehouseLocation?:any;
    warehouse?:any;
    lot?:any;
}

interface IProps{
    onClose?:any;
    onConfirm?:any;
    evaluatingProduct?:IEStockEvaluatingProduct;
    onSelectedLocation?:any;
    onNewLocation?:any;
    Id_InventoryEvaluationOrder?:any;
    
}


const CustomTableHead = styled(
	TableHead
 )((
	 {
		 theme
	 }
 ) => ({
 
    "& th":{
        backgroundColor: '#4b47a5',
        color:'white'
    }
 }));
const CustomTableCell = styled(
	TableCell
 )((
	 {
		 theme
	 }
 ) => ({
  padding:5
 }));

function IEStockCountingProductPopup(props:IProps){

    const {t} = useHxfTranslation();
    const [didMount, setDidMount] = useState(false);
    const [currentTab, setCurrentTab] = useState<"LATEST_RECORDS">("LATEST_RECORDS");
    const feedbackService = useFeedbackService();
    const [listRecords, setListRecords] = useState([]);
    const [listTotalSize,setListTotalSize] = useState(-1);
    const [totalCounted, setTotalCounted] = useState(-1);
    const [wasCountingConsideredFinished, setWasCountingConsideredFinished] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [presentAlert] = useIonAlert();
    const myLocalStorage = useLocalStorageHandler();

    const calculateTabLatestRecords = () => {
        
        setIsLoading(true);
        inventoryEvaluationOrderRecordsController().getListJson({
            Id_Product:props.evaluatingProduct?.Id_Product,
            Id_InventoryEvaluationOrder:props.Id_InventoryEvaluationOrder,
            includeWorkerData:true,
            includeLocationData:true,
            includeProductPPVVS:true,
            size:100,
            orderBy:'ieor.Id',
            orderDir:'DESC'
        }, {arrayProductParameterizedVariables: props?.evaluatingProduct?.arrayProductParameterizedVariables,checkIfProductFinishedCounting:true,calculateTotalCountedSelectedProduct:true}).then((res:any) => {
            setIsLoading(false);
            let resObj = res?.data?.response?.data;
   
            if(resObj?.totalSize === undefined){
                throw "unexpected";
            }

            setListRecords(resObj.return);
            setListTotalSize(resObj.totalSize);
            if(resObj?.extra?.productFinishedCounting){
                setWasCountingConsideredFinished(true);
            }else{
                setWasCountingConsideredFinished(false);
            }

            if(resObj?.extra?.productFinishedApproved){
                feedbackService.notifyToast(t('product.counting.already.approved'), "error");
                props.onClose();
            }

            setTotalCounted(resObj?.extra?.productTotalCounted ? resObj?.extra?.productTotalCounted : 0);
        }).catch((res:any) => {
            setIsLoading(false);
            feedbackService.notifyToast(t('error.unexpected'), "error");
            props.onClose();
        });
    }

    useEffect(() => {
        if(!didMount){

            calculateTabLatestRecords();

            setDidMount(true);
        }
    },[didMount]);


    const getSelectedLocationElem = () => {

        
        return (
            <div className={styles.selectedLocationContainer}>
                <div>
                    <div>
                        <div className={styles.locLabelTitle}>{t('warehouselocation')}</div>
                        <div>
                            {props.evaluatingProduct?.warehouseLocation?.name}
                        </div>
                    </div>
                    <div>
                        <div className={styles.locLabelTitle}>{t('warehouse')}</div>
                        <div>
                            {props.evaluatingProduct?.warehouse?.name}
                        </div>
                    </div>
                    <div>
                        <div className={styles.locLabelTitle}>{t('lot')}</div>
                        <div>
                            {props.evaluatingProduct?.lot?.code}
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                        <IonButton
                        className={styles.confirmButton}
                        onClick={() => {
                            props.onSelectedLocation({
                                Id_WarehouseLocation:props.evaluatingProduct?.Id_WarehouseLocation,
                                warehouseLocation:props.evaluatingProduct?.warehouseLocation,
                                Id_Warehouse:props.evaluatingProduct?.Id_WarehouseLocation,
                                warehouse:props.evaluatingProduct?.warehouse,
                                Id_Lot:props.evaluatingProduct?.Id_Lot,
                                lot:props.evaluatingProduct?.lot,
                            });
                        }}
                        >
                        <AddLocationAltIcon/>
                        {t('selected.location')}
                        </IonButton>
                    </div>
                </div>

            </div>
        )
    }
    const hasLocationSelected = () => {
        
        return props?.evaluatingProduct?.Id_WarehouseLocation ? true : false;
    }

    const getProperExpirationDateLabel = (expirationDate:any) => {

        let convertedDate = DatesConversionService().convertUTCStringToLocalDate(expirationDate);
  
        return (
          <>{DatesConversionService().formattedDateSimpleLabelDateOnly(convertedDate)}</>
        )
    }

    const isOwnRecord = (objRecord:any) => {
        let curCode = myLocalStorage.getWorkerIdentificationCode();

        return curCode === objRecord.workerCode;
    }

    return (
        <HxfIonPopup title={t('product.stock.counting')} content={(
            <div className={styles.content}>
                {isLoading && (
                    <HxfOverlayLoading/>
                )}
                {!wasCountingConsideredFinished && hasLocationSelected() && (
                    <div>
                        {getSelectedLocationElem()}
                    </div>
                )}
                <div className={styles.topBtns}>

                    <div>

                        {wasCountingConsideredFinished ? (
                            <>
                            <IonButton
                                color="danger"
                                className={styles.closeBtn}
                                onClick={() => {
                                    presentAlert({
                                        cssClass: "my-css",
                                        header: t('finish.product.count.close'),
                                        message: t("are.you.sure.close.product.count"),
                                        buttons: [
                                            t("cancel"),
                                            {
                                            text: t("yes"),
                                            handler: (d) => {
                                                setIsLoading(true);
                                                inventoryEvaluationOrderProductsController().finish({registerIfNotExists:true,finishedCounting:0,Id_InventoryEvaluationOrder:props.Id_InventoryEvaluationOrder,Id_Product:props.evaluatingProduct?.Id_Product, arrayProductParameterizedVariables: props.evaluatingProduct?.arrayProductParameterizedVariables,workerCode:myLocalStorage.getWorkerIdentificationCode()}).then((res:any) => {
                                                    setIsLoading(false);
                                                    if(!res.data.done){
                                                        throw "unexpected";
                                                    }
                                                    calculateTabLatestRecords();
                                                }).catch((res:any) => {
                                                    setIsLoading(false);
                                                    feedbackService.notifyToast(t('error.unexpected'), "error");
                                                });
                                            },
                                            },
                                        ],
                                        onDidDismiss: (e) => {},
                                        });
                                }}
                                >
                                    <IonIcon slot="start" size="large" icon={lockOpenOutline} />
                                    {t('counting.not.finished')}
                                </IonButton>
                            </>
                        ): (
                            <IonButton
                        
                            className={styles.closeBtn}
                            onClick={() => {
                                presentAlert({
                                    cssClass: "my-css",
                                    header: t('finish.product.count.close'),
                                    message: t("are.you.sure.close.product.count"),
                                    buttons: [
                                        t("cancel"),
                                        {
                                        text: t("yes"),
                                        handler: (d) => {
                                            setIsLoading(true);
                                            inventoryEvaluationOrderProductsController().finish({registerIfNotExists:true,finishedCounting:1,Id_InventoryEvaluationOrder:props.Id_InventoryEvaluationOrder,Id_Product:props.evaluatingProduct?.Id_Product, arrayProductParameterizedVariables: props.evaluatingProduct?.arrayProductParameterizedVariables,workerCode:myLocalStorage.getWorkerIdentificationCode()}).then((res:any) => {
                                                setIsLoading(false);
                                                if(!res.data.done){
                                                    throw "unexpected";
                                                }
                                                calculateTabLatestRecords();
                                            }).catch((res:any) => {
                                                setIsLoading(false);
                                                feedbackService.notifyToast(t('error.unexpected'), "error");
                                            });
                                        },
                                        },
                                    ],
                                    onDidDismiss: (e) => {},
                                    });
                            }}
                            >
                                <IonIcon slot="start" size="large" icon={checkmarkDoneSharp} />
                                {t('finish.counting')}
                            </IonButton>
                        )}

                    </div>


                    {!wasCountingConsideredFinished && (
                        <div className={styles.newLocBtn}>
                            <IonButton
                            color="success"
                            
                            onClick={() => {
                                props.onNewLocation();
                            }}
                            >
                            <AddLocationAltIcon/>
                            {t('new.location')}
                            </IonButton>
                        </div>
                    )}

                </div>
                {totalCounted !== -1 && (
                    <div style={{display:'flex',justifyContent:'center'}}>
                        <div>
                            <div className={styles.totalCountedLabel}>
                                {t('total.counted')}
                            </div>
                            <div className={styles.totalCountedValue} style={{display:'flex',justifyContent:'center'}}>
                                {totalCounted}
                            </div>
                        </div>
                    </div>
                )}

                <hr></hr>
                {wasCountingConsideredFinished && (
                   <div style={{margin:20}}>
                    <div style={{display:'flex',justifyContent:'center', color:'green'}}><IonIcon slot="start" size="large" icon={checkmarkDoneSharp} /></div>
                    <div className={styles.warnConsideredFinished}>{t('product.counting.considered.finished')}</div>
                   </div>
                )}
                <div>
                    {listRecords && listRecords.length > 0 ? (
                        <>
                <TableContainer component={Paper}>
                          <Table aria-label="simple table">
                              <CustomTableHead>
                                  <TableRow>
                                      <CustomTableCell><b>{t('product')}</b></CustomTableCell>
                          
                                      <CustomTableCell align="center"><b>{t('quantity')}</b></CustomTableCell>
                                      <CustomTableCell align="center">
                                        
                                          <b>{t('location')}</b>
                                       
                                      </CustomTableCell>
                                      <CustomTableCell align="right">
                                        
                                     
                                     
                                    </CustomTableCell>
                                  </TableRow>
                              </CustomTableHead>
                              <TableBody>
                                
                                  {listRecords.map((objRecord:any, index:any) => (
                                    <TableRow
                                       
                                      key={"cons_" + index}
                                      className={`${objRecord?.canceled === 1 ? styles.canceledRow : ""}`}
                                    
                                    >
                                      <CustomTableCell>
                                        <>
                                        <div className={styles.productNameCode}>({objRecord.productCode}) {objRecord.productName}</div>
                                        
                                        {objRecord?.arrayProductParameterizedVariables && (
                                          <div>
                                            {objRecord.arrayProductParameterizedVariables.map((objPVV:any, indexPVV:any) => (
                                              <div key={"cons_" + index+"_PV_" + indexPVV}><b>{objPVV.name}:</b> {objPVV.Value}</div>
                                            ))}
                                          </div>
                                        )}
                                        </>
                                      </CustomTableCell>

                                    
                                        <CustomTableCell align="center">

                                        <div className={styles.addedQt}>+{HxfNumberFormat().val(objRecord.Quantity)}</div>
                                         
                                        </CustomTableCell>
                                        <CustomTableCell align="center">
                                         <div>
                                            <div className={styles.locName}>{t('location')}</div>
                                            <div>{objRecord?.warehouseLocationCode}</div>
                                            <div className={styles.locName}>{t('warehouse')}</div>
                                            <div>{objRecord?.warehouseCode}</div>
                                            <div className={styles.locName}>{t('lot')}</div>
                                            <div>{objRecord?.lotCode}</div>
                                            {objRecord?.lotExpirationDate && (
                                              <div>
                                                <div className={styles.locName}>{t('expiration.date')}</div>
                                                <div className={styles.expirationLabel}>{getProperExpirationDateLabel(objRecord?.lotExpirationDate)}</div>
                                              </div>
                                            )}
                                         </div>
                                        </CustomTableCell>
                                        <CustomTableCell align="right">
                                                {isOwnRecord(objRecord) ? (
                                                    <div>
                                                        {wasCountingConsideredFinished ? (
                                                            <>--</>
                                                        ) : (
                                                            <>
                                                                {objRecord.canceled === 1 ? (
                                                                    <div>
                                                                        <IonButton
                                                                            color="success"
                                                                            
                                                                            onClick={() => {
                                                                                presentAlert({
                                                                                    cssClass: "my-css",
                                                                                    header: t('validate.record'),
                                                                                    message: t("are.you.sure.validate.record"),
                                                                                    buttons: [
                                                                                    t("cancel"),
                                                                                    {
                                                                                        text: t("yes"),
                                                                                        handler: (d) => {
                                                                                        setIsLoading(true);
                                                                                        inventoryEvaluationOrderRecordsController().cancelRecord({undoCancelMode:true,Id_InventoryEvaluationOrderRecord:objRecord.Id,workerCode:myLocalStorage.getWorkerIdentificationCode()}).then((res:any) => {
                                                                                            setIsLoading(false);
                                                                                            if(!res.data.done){
                                                                                                throw "unexpected";
                                                                                            }
                                                                                            calculateTabLatestRecords();
                                                                                        }).catch((res:any) => {
                                                                                            setIsLoading(false);
                                                                                            feedbackService.notifyToast(t('error.unexpected'), "error");
                                                                                        });
                                                                                        },
                                                                                    },
                                                                                    ],
                                                                                    onDidDismiss: (e) => {},
                                                                                });
                                                                            }}
                                                                        >
                                                                            <IonIcon slot="icon-only" size="large" icon={arrowUndoCircle} />
                                                                        </IonButton>
                                                                    </div>
                                                                ) : (<div>
                                                                    <IonButton
                                                                        color="danger"
                                                                        
                                                                        onClick={() => {
                                                                            presentAlert({
                                                                                cssClass: "my-css",
                                                                                header: t('cancel.record'),
                                                                                message: t("are.you.sure.cancel.record"),
                                                                                buttons: [
                                                                                t("cancel"),
                                                                                {
                                                                                    text: t("yes"),
                                                                                    handler: (d) => {
                                                                                    setIsLoading(true);
                                                                                    inventoryEvaluationOrderRecordsController().cancelRecord({Id_InventoryEvaluationOrderRecord:objRecord.Id,workerCode:myLocalStorage.getWorkerIdentificationCode()}).then((res:any) => {
                                                                                        setIsLoading(false);
                                                                                        if(!res.data.done){
                                                                                            throw "unexpected";
                                                                                        }
                                                                                        calculateTabLatestRecords();
                                                                                    }).catch((res:any) => {
                                                                                        setIsLoading(false);
                                                                                        feedbackService.notifyToast(t('error.unexpected'), "error");
                                                                                    });
                                                                                    },
                                                                                },
                                                                                ],
                                                                                onDidDismiss: (e) => {},
                                                                            });
                                                                        }}
                                                                    >
                                                                        <IonIcon slot="icon-only" size="large" icon={closeOutline} />
                                                                    </IonButton>
                                                                </div>)}
                                                            </>
                                                        )}
                                    
                                                    </div>
                                                ) : (
                                                    <>
                                                        <div className={styles.locName}>{t('created.by')}</div>
                                                        <div>{objRecord.workerName}</div>
                                                    </>
                                                )}

                                        </CustomTableCell>
                                    </TableRow>
                                  ))}

                              

                              </TableBody>
                          </Table>
                      </TableContainer>

                      {listTotalSize >= 100 && (
                        <div style={{display:'flex',justifyContent:'center',marginTop:10}}>
                            <b>{t('list.only.shows.the.latest.100.records')}</b>
                        </div>
                      )}
                        </>
                    ) : (
                        <div style={{display:'flex', justifyContent:'center'}}><i>{t('no.records.have.been.added')}</i></div>
                    )}

                         
                </div>
            </div>
        )} onClose={() => {
            props.onClose();
        }}/>
    )
}

export default IEStockCountingProductPopup;