import { useEffect, useRef, useState } from "react";
import stockMovementsController from "../../../barrel/controllers/stockMovementsController";
import useFeedbackService from "../../../barrel/hooks/useFeedbackService";
import useHxfTranslation from "../../../barrel/hooks/useHxfTranslation";
import { Backdrop, Checkbox, CircularProgress, InputAdornment, Paper, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import DatesConversionService from "../../../barrel/services/datesConversionService";
import styles from "./StockMovementsHistory.module.scss";
import { IonButton, IonIcon, useIonAlert } from "@ionic/react";
import { arrowUndo, closeCircle, logOutOutline } from "ionicons/icons";
import useLocalStorageHandler from "../../../barrel/hooks/useLocalStorageHandler";
import HxfIonLoading from "../../CustomElements/HxfIonLoading/HxfIonLoading";
import HxfOverlayLoading from "../../CustomElements/HxfOverlayLoading/HxfOverlayLoading";
interface IProps{
    Id_ShippingOrder?:any;

    onFailLoadMovements?:any;
}
interface ILoadedData{
    pageStockMovementsList?:any;
    totalSizeList?:any;
}


const CustomTableHead = styled(
	TableHead
 )((
	 {
		 theme
	 }
 ) => ({
 
    "& th":{
        backgroundColor: '#4b47a5',
        color:'white'
    }
 }));
 const CustomTableCell = styled(
	TableCell
 )((
	 {
		 theme
	 }
 ) => ({
  padding:5
 }));
 const CustomTableCellVar = styled(
	TableCell
 )((
	 {
		 theme
	 }
 ) => ({
  padding:7
 }));


function StockMovementsHistory(props:IProps){

    const myLocalStorage = useLocalStorageHandler();
    const [didMount, setDidMount] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const feedbackService = useFeedbackService();
    const {t} = useHxfTranslation();
 const [present, dismiss] = useIonAlert();
    const internalFilterSettings = useRef<any>({});

    const [loadedData, setLoadedData] = useState<ILoadedData>({});
    const [isLoadingUndo, setIsLoadingUndo] = useState(false);
    const getProductData = (movement:any) => {
        //using legacy mode json string
        
        let productData = movement?.productData;
        if(productData){
            let obj = JSON.parse(productData);
            return obj;
        }

        return null;

    }

    const getMovementLocation = (movement:any) => {

        //using legacy mode json string
        let warehouseLocationData = movement?.warehouseLocationData ? JSON.parse(movement?.warehouseLocationData) : null;
        let warehouseData = movement?.warehouseData ? JSON.parse(movement?.warehouseData) : null;
        let lotData = movement?.lotData ? JSON.parse(movement?.lotData) : null;

        if(!lotData && !warehouseLocationData && !warehouseData){
            return (<>{t('shopfloor')}</>)
        }

        if(!lotData){
            return (<>unexpected</>)
        }
        return (<>
            <div>
                {warehouseLocationData?.Id_WarehouseLocation && (
                   <div>
                     <div className={styles.locTitle}>{t('location')}</div>
                     <div>{warehouseLocationData?.code}</div>

                   </div>
                )}

                {warehouseData?.Id_Warehouse && (
                    <div>
                        <div className={styles.locTitle}>{t('warehouse')}</div>
                        <div>{warehouseData?.code}</div>
                    </div>
                )}

                {lotData?.Id_Lot && (
                    <div>
                        <div className={styles.locTitle}>{t('lot')}</div>
                        <div>{lotData?.code}</div>
                    </div>
                )}
            </div>
        </>)
    }

    useEffect(() => {
        if(!didMount){
            let opts:any = {
                size:100,
                includeWarehouseLocation:true,
                includeWarehouse:true,
                includeLot:true,
                includeWorker:true,
                includeProduct:true,
                includeWasRevertedCheck:true,
                excludeReversionMovements:true
            };
            if(props.Id_ShippingOrder){
                opts.Id_ShippingOrder = props.Id_ShippingOrder;
            }

            opts = {...opts,...internalFilterSettings.current};
            stockMovementsController().getListJson(opts).then((response:any) => {
               
                let respListMovements = response?.data?.response?.data?.return;
                let totalSize = response?.data?.response?.data.totalSize;

                if(respListMovements === null || respListMovements === undefined || totalSize === null || totalSize === undefined){
                    throw "unexpected.data.error";
                }

                setIsLoading(false);
                setLoadedData({
                    pageStockMovementsList:respListMovements,
                    totalSizeList:totalSize
                });
               
            }).catch((error:any) => {
                setIsLoading(false);
                feedbackService.notifyToast(t('error.unexpected'), "error");

                if(props?.onFailLoadMovements){
                    props.onFailLoadMovements();
                }
                
            });

            setDidMount(true);
        }
    }, [didMount]);



    if(isLoading){
        return (
            <>
            <HxfOverlayLoading/>
        </>
        )
    }
    return(
        <>

        {isLoadingUndo && (
            <HxfOverlayLoading/>
        )}
        {props.Id_ShippingOrder && (
            <div style={{margin:20, display:'flex',justifyContent:'center',alignItems:'center'}}>
                <i>{t('filtering.by.shipping.order')}</i>
            </div>
        )}

        {loadedData.pageStockMovementsList?.length === 0 ? (
            <><div style={{display:'flex', justifyContent:'center'}}><b>{t('no.movements.found')}</b></div></>
        ) : (
        <>
               <div style={{margin:10}}>
                <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <CustomTableHead>
                                <TableRow>
                                    <CustomTableCell><b>{t('date')}</b></CustomTableCell>
                                   
                                    <CustomTableCell align="center"><b>{t('worker')}</b></CustomTableCell>
                                    <CustomTableCell align="center"><b>{t('product')}</b></CustomTableCell>
                                    <CustomTableCell align="center"><b>{t('location')}</b></CustomTableCell>
                                    <CustomTableCell align="center"><b>{t('quantity')}</b></CustomTableCell>
                                    <CustomTableCell align="center">
                                    
                                        <b>{t('type')}</b>
                                    
                                    </CustomTableCell>
                                    <CustomTableCell align="center">
              
                                    </CustomTableCell>
                                </TableRow>
                            </CustomTableHead>
                            <TableBody>
                            
                            
                            {loadedData.pageStockMovementsList?.map((movement:any, index:any) => (
                                    <TableRow
                                    className={`${movement?._wasReverted ? styles.revertedMovementRow : ""}`}
                                    key={"sm_" +index}
                                    onClick={() => {
                                      
                                    }}
                                    >
                                        <CustomTableCell>
                                            <>
                                            {DatesConversionService().tableFormattedDateTimeLabel(movement.dateMovement)}
                                            </>
                                        </CustomTableCell>

                                        <CustomTableCell align="center">

                                        {movement?.workerData?.name ? movement?.workerData?.name : "N/A"}
                                        </CustomTableCell>
                                        <CustomTableCell align="center">
                                        <div>
                                            <div>({getProductData(movement)?.code})</div>
                                            {getProductData(movement)?.name}
                                        </div>
                                        </CustomTableCell>

                                        <CustomTableCell align="center">

                                        {getMovementLocation(movement)}
                                        </CustomTableCell>

                                        <CustomTableCell align="center">
                                           <div className={styles.qtyVal}>{movement?.Quantity > 0 ? "+" :""}{movement?.Quantity}</div>
                                        </CustomTableCell>
                                        <CustomTableCell align="center">

                                         {movement?.stockMovementType === "SHIPPED" && (
                                            <div style={{color:'green'}}>
                                                <div><IonIcon icon={logOutOutline} /></div>
                                               <div className={styles.smTypeLabel}><i>{t('shipped')}</i></div>
                                            </div>
                                         )}
                                        {movement?.stockMovementType === "MOVEMENT_REVERSION" && (
                                            <div style={{color:'rgb(131 25 25)'}}>
                                                <div><IonIcon icon={arrowUndo} /></div>
                                                <div className={styles.smTypeLabel}><i>{t('reversion')}</i></div>
                                            </div>
                                         )}

                                         {movement?._wasReverted ? (
                                            <div className={styles.revertedMovementLabel}>
                                                {t('reverted.movement')}
                                            </div>
                                         ): (<></>)}
                                        </CustomTableCell>
                                        <CustomTableCell align="center">
                                            <IonButton
                                                disabled={movement?._wasReverted || movement?.workerData?.code !== myLocalStorage.getWorkerIdentificationCode()}
                                                color="danger"
                                                onClick={() => {
                                                    present({
                                                        cssClass: "my-css",
                                                        header: t("undo.movement"),
                                                        message: t("undo.movement.confirm"),
                                                        buttons: [
                                                          "Cancel",
                                                          {
                                                            text: t("yes"),
                                                            handler: (d) => {
                                                              let currentWorkerCode = myLocalStorage.getWorkerIdentificationCode();
                                                              let idStockMovement = movement?.Id;
                                                              setIsLoadingUndo(true);
                                                              stockMovementsController().undoStockMovement({Id_StockMovement:idStockMovement,workerCode:currentWorkerCode}).then((response:any) => {
                                                                setIsLoadingUndo(false);
                                                                if(!response?.data?.done){
                                                                    throw "unexpected response";
                                                                }
                                                                feedbackService.notifyToast(t('movement.undone.success'), "success");
                                                               
                                                                let movementUpdated = {...movement, _wasReverted:true};

                                                                let curPageSms = loadedData.pageStockMovementsList;
                                                                curPageSms[index] = movementUpdated;
                                                                setLoadedData({...loadedData, pageStockMovementsList:curPageSms});
                                                              }).catch((error:any) => {
                                                                setIsLoadingUndo(false);
                                                                feedbackService.notifyToast(t('error.unexpected'), "error");
                                                              });
                                                            },
                                                          },
                                                        ],
                                                        onDidDismiss: (e) => {},
                                                      });
                                                }}
                                            >
                                                <IonIcon icon={arrowUndo} />
                                            </IonButton>
                                        </CustomTableCell>
                                    </TableRow>
                            ))}



                        </TableBody>
                    </Table>
                </TableContainer>
        </div>
        </>
        )} 
        </>
    )
}

export default StockMovementsHistory;