import { IonButton } from "@ionic/react";
import { Checkbox, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, styled } from '@mui/material';
import { useEffect, useState } from "react";
import productionController from "../../../../../../barrel/controllers/productionController";
import { useCancelToken } from "../../../../../../barrel/hooks/useCancelToken";
import useFeedbackService from "../../../../../../barrel/hooks/useFeedbackService";
import useHxfTranslation from "../../../../../../barrel/hooks/useHxfTranslation";
import useLocalStorageHandler from "../../../../../../barrel/hooks/useLocalStorageHandler";
import useWindowSize from "../../../../../../barrel/hooks/useWindowSize";
import CustomLoadingAction from "../../../../../../components/CustomLoadingAction";
import HxfTextfieldNumber from "../../../../../CustomElements/HxfTextfieldNumber/HxfTextfieldNumber";
import WSPopup from "../../../../../CustomElements/WSPopup/WSPopup";

import styles from "./EditPrMatchingFixedVariablesView.module.scss";
import HxfSelectorField from "../../../../../CustomElements/HxfSelectorField/HxfSelectorField";
interface IProps{
    onClose?:any;
    Id_ProductionRecord:any;
}

const CustomTableHead = styled(
	TableHead
 )((
	 {
		 theme
	 }
 ) => ({
 
    "& th":{
        backgroundColor: '#4b47a5',
        color:'white'    
    }
 }));


function EditPrMatchingFixedVariablesView(props:IProps){
    const {t} = useHxfTranslation();
    const [didMount, setDidMount] = useState(false);
    const myLocalStorage = useLocalStorageHandler()
    const [isLoading, setIsLoading] = useState(false);
    const feedbackService = useFeedbackService();
    const [width, height] = useWindowSize();
    const [loadedData, setLoadedData] = useState<any>(null);
    const { cancelToken, isCancel } = useCancelToken();

    const hasAtLeastOneChecklist = () => {

        let fvRecordsData = getFvsRecordsData();
        if(fvRecordsData.length === 0){
            return false;
        }

        for(let i = 0; i<fvRecordsData.length; i++){
            if(fvRecordsData[i].Id_ChecklistRecord){
                return true;
            }
        }

        return false;
    }

    const getChecklistRecordData = (idChecklistRecord:any) => {
        let opamfvvChecklistRecordPvvsData = loadedData?.opamfvvChecklistRecordPvvsData;
        if(!opamfvvChecklistRecordPvvsData){
            return [];
        }

        let returnData = [];
        for(let i = 0; i<opamfvvChecklistRecordPvvsData.length; i++){
            if(opamfvvChecklistRecordPvvsData[i].Id_ChecklistRecord === idChecklistRecord){
                returnData.push(opamfvvChecklistRecordPvvsData[i]);
            }
        }
   
        return returnData;
    }


    const getChecklistPvValue:any = (
        objChecklistRecordPvv:any, options:any = {}
      ) => {
       
        let filledChecklistData:any = [];
    
        let rawValue = objChecklistRecordPvv?.Value ? objChecklistRecordPvv.Value : null;
       
        let varType = objChecklistRecordPvv.varType;

        if (rawValue === null || rawValue === undefined) {
          if (varType === "text") {
            return "";
          }
          if (varType === "numeric") {
            return "";
          }
          if (varType === "checkbox") {
            return false;
          }
          if (varType === "optionselect") {
            return null;
          }
          return null;
        } else {
          if (varType === "optionselect") {
    
            if(options?.returnValueOnly){
              return rawValue;
            }
            return { label: rawValue, value: rawValue };
          }
        }
    
        return rawValue;
    
      };

  const getFieldVarType = (
    objChecklistRecordPvv: any
  ) => {
    let varName = objChecklistRecordPvv.name;

    if (objChecklistRecordPvv.varType === "text") {
      return (
        <div>
          <TextField
            disabled={false}
            error={false}
            onChange={(evt) => {
              /*setChecklistPvValue(
                indexChecklist,
                indexPv,
                evt.target.value,
                objPv.varType
              );*/
            }}
            type={"text"}
            value={getChecklistPvValue(objChecklistRecordPvv)}
            fullWidth={true}
            label={varName}
            variant="outlined"
          />
        </div>
      );
    }

    if (objChecklistRecordPvv.varType === "numeric") {
      return (
        <div>
          <TextField
            disabled={false}
            error={false}
            onChange={(evt) => {
              /*
              setChecklistPvValue(
                indexChecklist,
                indexPv,
                evt.target.value,
                objPv.varType
              );*/

            }}
            type={"number"}
            value={getChecklistPvValue(objChecklistRecordPvv)}
            fullWidth={true}
            label={varName}
            variant="outlined"
          />
        </div>
      );
    }

    if (objChecklistRecordPvv.varType === "checkbox") {
      return (
        <div className={styles.checkboxCustom}>
          <div className={styles.checkboxCustomInner}>
            <Checkbox
              color={"primary"}
              onChange={(evt) => {
                /*let newVal = evt.target.checked;

                console.log("ONCHG: ", evt.target.checked);
                setChecklistPvValue(
                  indexChecklist,
                  indexPv,
                  evt.target.checked,
                  objPv.varType
                );*/
              }}
              checked={getChecklistPvValue(objChecklistRecordPvv)}
            />
          </div>
        </div>
      );
    }

    if (objChecklistRecordPvv.varType === "optionselect") {
      let optionsSelectableArr = objChecklistRecordPvv.varTypeOptionSelect_Opts.split(",");

      let defaultOptionsObj = [];
      for (let i = 0; i < optionsSelectableArr.length; i++) {
        defaultOptionsObj.push({
          label: optionsSelectableArr[i],
          value: optionsSelectableArr[i],
        });
      }

      return (
        <div className={styles.customChecklistComboSelectOptions}>
          <HxfSelectorField
            error={false}
            uniqueId={"hxfsel_" + objChecklistRecordPvv.Id}
            disabled={false}
            disableClearable={true}
            onChange={(evt:any, val:any) => {

              let currentLoadedData = {...loadedData};
              let opamfvvChecklistRecordPvvsData = currentLoadedData?.opamfvvChecklistRecordPvvsData;
              for(let i = 0; i<opamfvvChecklistRecordPvvsData.length; i++){
                if(opamfvvChecklistRecordPvvsData[i].Id_ChecklistRecord === objChecklistRecordPvv.Id_ChecklistRecord){
                  opamfvvChecklistRecordPvvsData[i].Value = val.value;
                  break;
                }
                
              }

              setLoadedData({...loadedData, opamfvvChecklistRecordPvvsData:opamfvvChecklistRecordPvvsData});
             
            }}
            value={getChecklistPvValue(objChecklistRecordPvv)}
            defaultItems={defaultOptionsObj}
            labelPlaceholder={varName}
          />
        </div>
      );
    }

    return <>not implemented</>;
  };

    const getChecklistInputs = (obj:any, index:any) => {
    
        if(!obj.Id_ChecklistRecord){
            return (<></>)
          }
      
          let checklistPvsData = getChecklistRecordData(obj.Id_ChecklistRecord);
      
          if(checklistPvsData.length === 0){
            return (<></>) 
          }
      

          return (<div className={styles.checklistsFieldsContainer}>
                    <div>
                      {checklistPvsData?.map(
                          (objPv: any, indexPv: number) => (
                       
                             <div
                              key={"chklist_" + index + "_pv_" + indexPv}
                              className={styles.checklistField}
                            >
                    
                                  {getFieldVarType(objPv)}

         
      
                            </div>
      
                          )
                        )}
                    </div>
      
                    </div>
                  )
    }
    const getFvsRecordsData = () => {
        if(!loadedData?.opamfvRecordsData){
            return [];
        }

        return loadedData?.opamfvRecordsData;
    }

    useEffect(() =>{

        if(!didMount){
            let currentWorkerCode = myLocalStorage.getWorkerIdentificationCode();
           productionController().getById(props.Id_ProductionRecord,{workerCode:currentWorkerCode,includeMatchedFixedVariablesRecords:true}).then((res:any) => {
                setIsLoading(false);
               

                //opamfvvChecklistRecordPvvsData
                let data = res?.data?.response?.data;
                if(!data){
                    throw "unexpected error";
                }
             
                setLoadedData(data);

           }).catch((res:any) => {
                setIsLoading(false);
                feedbackService.notifyToast(t('generic.critical.error'),"error");
                props.onClose();
           });
           setDidMount(true);
        }
    },[didMount, props.Id_ProductionRecord,myLocalStorage,feedbackService,t, props]);

    if(isLoading || !loadedData){
        return (<CustomLoadingAction/>)
    }
    return (<>
    <WSPopup title="Matching fixed vars" onClose={() => {
        if(props?.onClose){
            props.onClose();
        }
    }} 
    
    content={(<>
    <div>
        <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <CustomTableHead>
                            <TableRow>
                                <TableCell><b>{t('variable')}</b></TableCell>
                                {width >= 540 && hasAtLeastOneChecklist() && (
                                    <TableCell align="left" className={styles.biggerScreensShow}><b>{t('information')}</b></TableCell>
                                )}
                                </TableRow>
                        </CustomTableHead>
                        <TableBody>
                

                
                            {getFvsRecordsData().map((objFixVarRecord:any, indexObjFvr:any) => (
                            <TableRow key={"resMatchingFixVar_" + indexObjFvr}>
                                    <TableCell align="left"> 
                                    <div className={styles.matchFixVarContainer}>
                                        <div className={styles.inputContainer}>

                                            <HxfTextfieldNumber fullWidth={true}  centerInputText={true} error={false} label={objFixVarRecord.name} placeholder={objFixVarRecord.name} onChange={(val:any) => {
                                                    let currentRecordsData = getFvsRecordsData();
                                                    currentRecordsData[indexObjFvr].Value = val;

                                                    setLoadedData({...loadedData, opamfvRecordsData: currentRecordsData});
                                                }} value={objFixVarRecord.Value}/> 
                                        
                                        </div>
                                        </div>
                                        {width < 540 && hasAtLeastOneChecklist() && (
                                        <div className={styles.inputContainer}>
                                            {getChecklistInputs(objFixVarRecord, indexObjFvr)}
                                        </div>
                                        )}         

                                    </TableCell>
                                    
                                    

                                    {width >= 540 && hasAtLeastOneChecklist() && (
                                        <TableCell>
                                            <div className={styles.inputContainer}>
                                            {getChecklistInputs(objFixVarRecord, indexObjFvr)}
                                            </div>
                                        </TableCell>
                                    )}   
                                    
                                </TableRow>
                        

                            ))}


                </TableBody>
            </Table>
        </TableContainer>
    </div>
    </>)}
    confirmationContentArea={(
        <div
        className={`popup-bottom-confirmation-border-styles ${styles.bottomAreaContainer}`}
      >
        <div>
          <div className={styles.container}>
            <div className={styles.buttonsContainer}>
              <div className={`${styles.customConfirmationContainer} `}>
  
            
                <div className={`${styles.customConfirmationContainer} ${styles.continueBtn} `}>
                  <IonButton
                        size={"large"}
                      style={{width:'100%'}}
                      className={styles.confirmButton}
                      onClick={() => {

                            let submittingObj:any = {includeMatchedFixedVariablesRecords:true};
                            submittingObj.opamfvvChecklistRecordPvvsData = loadedData.opamfvvChecklistRecordPvvsData;
                            submittingObj.opamfvRecordsData = loadedData.opamfvRecordsData;
                            setIsLoading(true);
                            productionController().workstationEdit(props.Id_ProductionRecord, submittingObj, cancelToken).then((res) => {
                                let data = res?.data?.response?.data;
                                if(!data){
                                    throw "unexpected result";
                                }
                                setIsLoading(false);
                                feedbackService.notifyToast(t('saved.successfully'),"success");
                                props.onClose();
                             
                            }).catch((res)=> {
                                if (isCancel(res)) {
                                    return;
                                  }
                                setIsLoading(false);
                                feedbackService.notifyToast(t('generic.critical.error'),"error");
                            });
                      }}
                    >
                      {t('save')}
                  </IonButton>
                </div>
             

            </div>
          </div>
        </div>
        </div>
      </div>
    )} />
    </>)
}

export default EditPrMatchingFixedVariablesView;