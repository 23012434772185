import styles from "./ActionScreenProduceQuantityProduct.module.scss";
import { IonButton } from "@ionic/react";
import ActionScreenTopBar from "../Components/ActionScreenTopBar";
import HxfDigitKeyboard from "../../../../HxfDigitKeyboard/HxfDigitKeyboard";
import { useEffect, useState } from "react";
import { useActionScreenManagerStore } from "../../ActionScreensManager/ActionScreenManagerStore";
import { dispatchProductionOrderSelectionStore, useProductionOrderSelectionStore } from "../../../../../FeaturePages/ProductionOrders/Standard/store/productionOrderSelectionStore";
import {
  dispatchActionScreenProduceQuantityProductStore,
  useActionScreenProduceQuantityProductStore,
} from "./ActionScreenProduceQuantityProductStore";
import HxfSelectorField from "../../../../HxfSelectorField/HxfSelectorField";
import warehousesController from "../../../../../../barrel/controllers/warehousesController";
import lotsController from "../../../../../../barrel/controllers/lotsController";
import warehouseLocationsController from "../../../../../../barrel/controllers/warehouseLocationsController";
import confirmationStyles from "../Components/ActionScreenConfirmationButton.module.scss";
import {
  dispatchActionScreenAskBomConsumptionsStore,
  useActionScreenAskBomConsumptionsStore,
} from "../ActionScreenAskBomConsumptions/ActionScreenAskBomConsumptionsStore";
import TextField from "@mui/material/TextField";
import useActionPreConfirm from "../../../../../../barrel/hooks/useActionPreConfirm";
import useHxfTranslation from "../../../../../../barrel/hooks/useHxfTranslation";
import { Trans } from "react-i18next";
import { dispatchActionScreenScaleProductivityStore } from "../ActionScreenScaleProductivity/ActionScreenScaleProductivityStore";
import useFeedbackService from "../../../../../../barrel/hooks/useFeedbackService";
import { productionOperationSessionStats } from "../../ProductionOperationStore";
import HxfOverlayLoading from "../../../../HxfOverlayLoading/HxfOverlayLoading";
import HxfDatePicker from "../../../../HxfDatePicker/HxfDatePicker";
import useProductivityScale from "../../../../../../barrel/hooks/useProductivityScale";
interface IResult {
  quantity: number;
  Id_Warehouse?: number;
  Id_Lot?: number;
  //newLot_name?: string;
  newLot_code?: string;
  Id_WarehouseLocation?: number;
}

function ActionScreenProduceQuantityProduct(props: any) {
  const {t} = useHxfTranslation();
  const [indexkey, setIndexkey] = useState(0);
  const [showErrorInput, setShowErrorInput] = useState(false);

  const productionOrderSelectionStore = useProductionOrderSelectionStore();
  const screenState = useActionScreenProduceQuantityProductStore();
  const preConfirm = useActionPreConfirm();
  const productivityScale = useProductivityScale();
  const askBomScreenState = useActionScreenAskBomConsumptionsStore();
  const currentActionScreenManager = useActionScreenManagerStore();
  const [currentStep, setCurrentStep] = useState("CONFIRM_QTY");
  const [hasDefinedProdLotFinal,setHasDefinedProdLotFinal] = useState(false);
  const [initiallyDefinedLot, setInitiallyDefinedLot] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const operationSessionStats = productionOperationSessionStats();

  const [
    loadedWarehouseLocationListJsonCache,
    setWarehouseLocationLoadedListJsonCache,
  ] = useState<Array<any>>([]);

  const [loadedWarehouseListJsonCache, setWarehouseLoadedListJsonCache] =
    useState<Array<any>>([]);

  const [loadedLotListJsonCache, setLotListJsonCache] = useState<Array<any>>(
    []
  );
  const [warehouseSearchFilters, setWarehouseSearchFilters] = useState({});
  const [lotSearchFilters, setLotSearchFilters] = useState({});
  const [result, setResult] = useState<IResult>({ quantity: 0 });
  const feedbackService = useFeedbackService();
  //stock location field errors
  const [lotExpirationError,setLotExpirationError] = useState(false);
  const [lotError, setLotError] = useState(false);
  const [warehouseError, setWarehouseError] = useState(false);
  const [warehouseLocationError, setWarehouseLocationError] = useState(false);

  // states to allow clearing when loading a default stock location config
  const [clearedWarehouse, setClearedWarehouse] = useState(false);
  const [clearedWarehouseLocation, setClearedWarehouseLocation] =
    useState(false);
  const [clearedLot, setClearedLot] = useState(false);
  const [didMount, setDidMount] = useState(false);
  const [remountKeypadIndex, setRemountKeypadIndex] = useState(0);


  const useDefaultNoLotIfNoLotSelected = currentActionScreenManager?.currentActionConfig?.warehouseUseBaseLotOnEmptyLot === 1; //flag to become dynamic in the future

  const [defaultNoLot, setDefaultNoLot] = useState<any>(null);

  const callbackAddWarehouseLocationCacheItems = (items: Array<any>) => {
    setWarehouseLocationLoadedListJsonCache([
      ...loadedWarehouseLocationListJsonCache,
      ...items,
    ]);
  };

  const getProperUomLabel = () => {

    let layout = currentActionScreenManager?.currentActionConfig;
    if(layout?.customUnitText){
      return layout.customUnitText;
    }
 
    return "pcs";
  }

  const callbackAddWarehouseCacheItems = (items: Array<any>) => {
    setWarehouseLoadedListJsonCache([
      ...loadedWarehouseListJsonCache,
      ...items,
    ]);
  };

  const getWarehouseCacheItem = (id: any) => {
    for (let i = 0; i < loadedWarehouseListJsonCache.length; i++) {
      if (loadedWarehouseListJsonCache[i].Id === id) {
        return loadedWarehouseListJsonCache[i];
      }
    }

    return null;
  };
  const callbackAddLotCacheItems = (items: Array<any>) => {
    setLotListJsonCache([...loadedLotListJsonCache, ...items]);
  };

  function forceRerender() {
    setIndexkey(indexkey + 1);
  }

  function confirmQuantity(res: any) {
    if (isNaN(res) || res === "") {
      setShowErrorInput(true);
      forceRerender();
      return false;
    }
    let valueNumber = parseFloat(res) + 0;

    let allowZeroQuantity =
      currentActionScreenManager?.currentActionConfig
        ?.allowZeroQuantityProduction;

    let newResult:any = {...result};
    let generatedMovement = currentActionScreenManager?.currentActionConfig?.generatedMovement;
    if(generatedMovement){
      newResult.generatedMovement = generatedMovement;
    }
    
    if (allowZeroQuantity && valueNumber === 0) {
       newResult = { ...newResult, quantity: 0 };
      setResult(newResult);
      return newResult;
    } else if (valueNumber === 0 && !allowZeroQuantity) {
      setShowErrorInput(true);
      forceRerender();
      return false;
    } else {
      //let returnObj = {quantity:valueNumber};
      newResult = { ...newResult, quantity: valueNumber };
      setResult(newResult);
      return newResult;
      //props.callbackSuccessConfirm(returnObj);
    }

    return false;
  }

  function confirmStockLocation() {
    let newResult = result;
    let acceptLotEmpty =
      useDefaultNoLotIfNoLotSelected && defaultNoLot !== null;

    let requireFillStockLocation = currentActionScreenManager.currentActionConfig?.requireFillStockLocation == 1;

    //if nothing set, default shopfloor
    if (
      screenState?.Id_WarehouseLocation === 0 &&
      screenState?.Id_Warehouse === 0 &&
      screenState?.Id_Lot === 0
    ) {
      if(requireFillStockLocation){
        setWarehouseLocationError(true);
        setWarehouseError(true);
        setLotError(true);
        return false;
      }
      return {
        ...newResult,
        Id_WarehouseLocation: 0,
        Id_Lot: 0,
        Id_Warehouse: 0,
      };
    } else if (
      screenState?.Id_WarehouseLocation !== 0 &&
      screenState?.Id_Warehouse !== 0 &&
      (screenState?.Id_Lot !== 0 || acceptLotEmpty)
    ) {
      let isExpirationDateRequired = currentActionScreenManager?.currentActionConfig?.requireLotExpirationDate === 1;
    
     

      if(isExpirationDateRequired && !screenState?.expirationDate){
        setLotExpirationError(true);
        return false;
      }
      
      //setting to determinate if the new lot is different from the initial (example, lot inheritances)
      let isRequiredLotModified = currentActionScreenManager?.currentActionConfig?.requireLotChanging === 1;

      if(isRequiredLotModified && initiallyDefinedLot?.Id){
        if(initiallyDefinedLot.Id === "new"){
          //compare by code
          if(initiallyDefinedLot?.lotCode && screenState?.temp_Lot_code){
            if(initiallyDefinedLot?.lotCode === screenState?.temp_Lot_code){
              feedbackService.notifyToast(t('lot.must.be.modified'), "error");
              setLotError(true);
              return false;
            }
          }

        }else{
          //compare by Id
          if(parseInt(initiallyDefinedLot.Id) === parseInt(screenState?.Id_Lot)){
            feedbackService.notifyToast(t('lot.must.be.modified'), "error");
            setLotError(true);
            return false;
          }
        }
      }
      //all fields are set, wh, whloc and lot

      //

      let returnObj:any = {
        ...newResult,
        Id_WarehouseLocation: screenState.Id_WarehouseLocation,
        Id_Warehouse: screenState.Id_Warehouse,
        Id_Lot: screenState.Id_Lot,
      };

      if(screenState?.expirationDate){
        returnObj.newLot_expirationDate = screenState.expirationDate;
      }

      if (acceptLotEmpty && !returnObj.Id_Lot) {
        returnObj.Id_Lot = defaultNoLot.Id_Lot;
      } else if (returnObj.Id_Lot === "new") {
        returnObj = {
          ...returnObj,
          //newLot_name: screenState.temp_Lot_label,
          newLot_code: screenState.temp_Lot_label
        };
      }
      return returnObj;
    } else {
      //not all = 0 and not all set, show error
      if (screenState?.Id_WarehouseLocation === 0) {
        setWarehouseLocationError(true);
      }
      if (screenState?.Id_Warehouse === 0) {
        setWarehouseError(true);
      }
      if (screenState?.Id_Lot === 0) {
        setLotError(true);
      }
      return false;
    }

    return false;
  }


  const getCurrentActionTempStoreState = () => {
    let currentOperationActionIndex = productionOrderSelectionStore.currentOperationActionIndex;
    let currentOperationData = {...productionOrderSelectionStore.currentOperation};
    let currentOperationActions = currentOperationData.actions;
    let currentOperationAction = currentOperationActions[currentOperationActionIndex];
    return currentOperationAction;
  }
  const updateCurrentActionTempStoreState = (data:any) => {
    let currentOperationActionIndex = productionOrderSelectionStore.currentOperationActionIndex;
    let currentOperationData = {...productionOrderSelectionStore.currentOperation};
    let currentOperationActions = currentOperationData.actions;
    let currentOperationAction = currentOperationActions[currentOperationActionIndex];
    currentOperationAction._tempStore = {
      ...data
    };
    currentOperationActions[currentOperationActionIndex] = currentOperationAction;

    currentOperationData.actions = currentOperationActions;
    
    dispatchProductionOrderSelectionStore({
      type: "SET_CURRENT_OPERATION",
      payload: JSON.parse(JSON.stringify(currentOperationData)),
    });


  }
  const finishConfirm = (storeResult:any,scaleProductivityVal:any = null) => {
    let instantLotCreation = currentActionScreenManager?.currentActionConfig?.instantLotCreation === 1;
    
    if(instantLotCreation){



      if(screenState?.Id_Lot === "new"){
        let newLotCode = screenState?.temp_Lot_code;
        if(!newLotCode){
          feedbackService.notifyToast(t('invalid.lot'),"error");
          return;
        }
        if(!screenState?.Id_Warehouse){
          feedbackService.notifyToast(t('invalid.warehouse'),"error");
          return;
        }

        let expirationDate = screenState?.expirationDate;
        let objLotSave:any = {
          name:newLotCode,
          code:newLotCode,
          Id_Warehouse:screenState.Id_Warehouse,
          _ifAlreadyExistsReturnLotData:true
        };
        if(expirationDate){
          objLotSave._isDefiningLotExpiration = true;
          objLotSave.expirationDate = expirationDate;
        }

        setIsLoading(true);
        //attempt to reserve lot and set current state
        lotsController().save({
          object:objLotSave
        }).then((res:any) => {
          setIsLoading(false);
          //if lot already exist, get the Id of the lot instead
          let success = res?.data?.existingLot?.Id || res?.data?.response?.data?.Id;
          if(!success){
            throw "unexpected";
          }
          let idLot = null;
          if(res?.data?.existingLot?.Id){
            idLot = res?.data?.existingLot?.Id; 
          }else if(res?.data?.response?.data?.Id){
            idLot = res?.data?.response?.data?.Id;
          }
          if(!idLot){
            throw "unexpected";
          }
          
          updateCurrentActionTempStoreState({...getCurrentActionTempStoreState()?._tempStore, Id_Lot:idLot});
          props.callbackSuccessConfirm(storeResult,scaleProductivityVal);
        }).catch((res:any) => {
          setIsLoading(false);
          feedbackService.notifyToast(t('error.unexpected'), "error");
        });
      }else{
        
        updateCurrentActionTempStoreState({...getCurrentActionTempStoreState()?._tempStore, Id_Lot:screenState?.Id_Lot});
        props.callbackSuccessConfirm(storeResult,scaleProductivityVal);
      }
 
    }else{
      //default behaviour
      props.callbackSuccessConfirm(storeResult,scaleProductivityVal);
    }
    
  }

  function stepConfirm(res: any) {
    if(!preConfirm.check()){
      return;
    }
    
    if (currentStep === "CONFIRM_QTY") {
      let storeResult = confirmQuantity(res);
      if (storeResult !== false) {
        if (
          currentActionScreenManager.currentActionConfig
            ?.allowManualStockSet === 1 &&
          currentActionScreenManager.currentActionConfig?.allowManualLotSet ===
            1
        ) {
          setCurrentStep("STOCK_LOCATION_SET");
        } else {
          let scaleProductivityVal = null;
        
          if(currentActionScreenManager?.currentActionConfig?.scaleProductivityByProducedQuantity === 1){
           

            //get prev get new

            //operationSessionStats.modifiedScaleProductivity();

            scaleProductivityVal = storeResult;
          
            dispatchActionScreenScaleProductivityStore({
              type: "SET_QUANTITY",
              payload: storeResult?.quantity,
            });
          }
          finishConfirm(storeResult,scaleProductivityVal);
        }
      }
    }

    if (currentStep === "STOCK_LOCATION_SET") {
      let storeResult = confirmStockLocation();

      if (storeResult !== false) {
        
        finishConfirm(storeResult);
      }
    }
  }

  const getWarehouseLocationState = () => {
    if (screenState.Id_WarehouseLocation !== 0) {
      //check if has selected in state

      return {
        label: screenState.temp_WarehouseLocation_label,
        value: screenState.Id_WarehouseLocation,
      };
    } else if (
      !clearedWarehouseLocation &&
      currentActionScreenManager.currentActionConfig?.defaultProductionLocation
        ?.Id_WarehouseLocation
    ) {
      //check if has production location default settings
      return {
        label:
          currentActionScreenManager.currentActionConfig
            ?.defaultProductionLocation?.warehouseLocationLabel,
        value:
          currentActionScreenManager.currentActionConfig
            ?.defaultProductionLocation?.Id_WarehouseLocation,
      };
    } else if (
      !clearedWarehouseLocation &&
      currentActionScreenManager.currentActionConfig?.Id_WarehouseLocation
    ) {
      //check if has location defined in the operation action
      return {
        label:
          "(" +
          currentActionScreenManager.currentActionConfig
            ?.temp_warehouseLocation_code +
          ")" +
          currentActionScreenManager.currentActionConfig
            ?.temp_warehouseLocation_name,
        value:
          currentActionScreenManager.currentActionConfig?.Id_WarehouseLocation,
      };
    }

    return null;
  };
  const getWarehouseState = () => {
    if (screenState.Id_Warehouse !== 0) {
      //check if has selected in state

      return {
        label: screenState.temp_Warehouse_label,
        value: screenState.Id_Warehouse,
      };
    } else if (
      !clearedWarehouse &&
      currentActionScreenManager.currentActionConfig?.defaultProductionLocation
        ?.Id_Warehouse
    ) {
      //check if has production location default settings
      return {
        label:
          currentActionScreenManager.currentActionConfig
            ?.defaultProductionLocation?.warehouseLabel,
        value:
          currentActionScreenManager.currentActionConfig
            ?.defaultProductionLocation?.Id_Warehouse,
      };
    } else if (
      !clearedWarehouse &&
      currentActionScreenManager.currentActionConfig?.Id_Warehouse
    ) {
      //check if has location defined in the operation action
      return {
        label:
          "(" +
          currentActionScreenManager.currentActionConfig?.temp_warehouse_code +
          ")" +
          currentActionScreenManager.currentActionConfig?.temp_warehouse_name,
        value: currentActionScreenManager.currentActionConfig?.Id_Warehouse,
      };
    }

    return null;
  };

  const getExpirationDateState = () => {
    return screenState?.expirationDate ? new Date(screenState?.expirationDate) : null;
  }

  const getInputLotState = () => {
    let lotState = getLotState(true);
    if (!lotState) {
      return "";
    }

    return lotState;
  };
  const getLotState = (isTextInputField = false) => {
    if (screenState.Id_Lot !== 0) {
      //check if has selected in state

      if (isTextInputField) {
        return screenState.temp_Lot_code;
      }
      return { label: screenState.temp_Lot_label, value: screenState.Id_Lot };
    } else if (
      !clearedLot &&
      currentActionScreenManager.currentActionConfig?.defaultProductionLocation
        ?.Id_Lot
    ) {
      //check if has production location default settings

      if (isTextInputField) {
        return currentActionScreenManager.currentActionConfig
          ?.defaultProductionLocation?.lotCode;
      }
      return {
        label:
          currentActionScreenManager.currentActionConfig
            ?.defaultProductionLocation?.lotLabel,
        value:
          currentActionScreenManager.currentActionConfig
            ?.defaultProductionLocation?.Id_Lot,
      };
    } else if (
      !clearedLot &&
      currentActionScreenManager.currentActionConfig?.Id_Lot
    ) {
      //check if has location defined in the operation action

      if (isTextInputField) {
        return currentActionScreenManager.currentActionConfig?.temp_lot_code;
      }
      return {
        label:
          "(" +
          currentActionScreenManager.currentActionConfig?.temp_lot_code +
          ")" +
          currentActionScreenManager.currentActionConfig?.temp_lot_name,
        value: currentActionScreenManager.currentActionConfig?.Id_Lot,
      };
    }

    return null;
  };
  const getDefaultNoLotForWarehouse = (warehouseCacheObj: any) => {
    let defaultNoLot = warehouseCacheObj.defaultNoLot;
    defaultNoLot = JSON.parse(defaultNoLot);
    if (defaultNoLot.Id_Lot !== null) {
      return {
        Id_Lot: parseInt(defaultNoLot.Id_Lot),
        name: defaultNoLot.name,
        code: defaultNoLot.code,
      };
    }

    return null;
  };
  useEffect(() => {
    if (!didMount) {

      let Id_WarehouseLocation = 0;
      let warehouseLocationLabel = "";
      let Id_Warehouse = 0;
      let warehouseLabel = "";
      let Id_Lot:any = 0;
      let lotLabel = "";
      let lotCode = "";
      
      let poData = productionOrderSelectionStore;


      let prodFinalLotCode = null;
      let prodFinalLotExpDate = null;

      if(currentActionScreenManager?.currentActionConfig?.inheritLotFromPop === 1 && poData?.productionOrderProduct?.prodFinalLotCode){
        prodFinalLotCode = poData?.productionOrderProduct?.prodFinalLotCode;
      }

      if(currentActionScreenManager?.currentActionConfig?.inheritLotExpDateFromPop === 1 && poData?.productionOrderProduct?.prodFinalLotExpDate){
        prodFinalLotExpDate = poData?.productionOrderProduct?.prodFinalLotExpDate;
      }
      

      if(prodFinalLotCode){
        setHasDefinedProdLotFinal(true);
        Id_Lot = "new";
        lotLabel = prodFinalLotCode;
        lotCode = prodFinalLotCode;
      }

      if (
        currentActionScreenManager.currentActionConfig?.defaultProductionLocation?.Id_WarehouseLocation
      ) {
        //check default production location settings first
        if (
          currentActionScreenManager.currentActionConfig
            ?.defaultProductionLocation?.Id_Warehouse !== 0
        ) {
          setLotSearchFilters({
            Id_Warehouse:
              currentActionScreenManager.currentActionConfig
                ?.defaultProductionLocation?.Id_Warehouse,
          });
        }

        setWarehouseSearchFilters({
          Id_WarehouseLocation:
            currentActionScreenManager.currentActionConfig
              ?.defaultProductionLocation?.Id_WarehouseLocation,
        });
        Id_WarehouseLocation = currentActionScreenManager.currentActionConfig?.defaultProductionLocation?.Id_WarehouseLocation;
        if (!Id_WarehouseLocation) {
          Id_WarehouseLocation = 0;
        }
        if (Id_WarehouseLocation !== 0) {
          warehouseLocationLabel = currentActionScreenManager.currentActionConfig?.defaultProductionLocation?.warehouseLocationLabel;
        }
        Id_Warehouse = currentActionScreenManager.currentActionConfig?.defaultProductionLocation?.Id_Warehouse;
        if (!Id_Warehouse) {
          Id_Warehouse = 0;
        }
        if (Id_Warehouse !== 0) {
          warehouseLabel =
            currentActionScreenManager.currentActionConfig
              ?.defaultProductionLocation?.warehouseLabel;
        }

        Id_Lot = currentActionScreenManager.currentActionConfig?.defaultProductionLocation?.Id_Lot;
        if (!Id_Lot) {
          Id_Lot = 0;
        }
        if (Id_Lot !== 0) {
          lotLabel =
            currentActionScreenManager.currentActionConfig
              ?.defaultProductionLocation?.lotLabel;

          lotCode =
            currentActionScreenManager.currentActionConfig
              ?.defaultProductionLocation?.lotCode;
        }


        if (currentActionScreenManager.currentActionConfig?.defaultProductionLocation?.defaultNoLot) {
          setDefaultNoLot(
            getDefaultNoLotForWarehouse(
              currentActionScreenManager.currentActionConfig
            )
          );
        }
      } else if ( currentActionScreenManager.currentActionConfig?.Id_WarehouseLocation !== 0) {
        //check operation location settings if they exist
        if (
          currentActionScreenManager.currentActionConfig?.Id_Warehouse !== 0
        ) {
          setLotSearchFilters({
            Id_Warehouse:
              currentActionScreenManager.currentActionConfig?.Id_Warehouse,
          });
        }

        setWarehouseSearchFilters({
          Id_WarehouseLocation:
            currentActionScreenManager.currentActionConfig
              ?.Id_WarehouseLocation,
        });
        Id_WarehouseLocation =
          currentActionScreenManager.currentActionConfig?.Id_WarehouseLocation;
        if (Id_WarehouseLocation !== 0) {
          warehouseLocationLabel =
            "(" +
            currentActionScreenManager.currentActionConfig
              .temp_warehouseLocation_code +
            ") " +
            currentActionScreenManager.currentActionConfig
              .temp_warehouseLocation_name;
        }
        Id_Warehouse =
          currentActionScreenManager.currentActionConfig?.Id_Warehouse;
        if (Id_Warehouse !== 0) {
          warehouseLabel =
            "(" +
            currentActionScreenManager.currentActionConfig.temp_warehouse_code +
            ") " +
            currentActionScreenManager.currentActionConfig.temp_warehouse_name;
        }
        Id_Lot = currentActionScreenManager.currentActionConfig?.Id_Lot;
        if (Id_Lot !== 0) {
          lotLabel =
            "(" +
            currentActionScreenManager.currentActionConfig.temp_lot_code +
            ") " +
            currentActionScreenManager.currentActionConfig.temp_lot_name;
          lotCode =
            currentActionScreenManager.currentActionConfig?.temp_lot_code;
        }

        if(prodFinalLotCode){
          Id_Lot = "new";
          lotLabel = prodFinalLotCode;
          lotCode = prodFinalLotCode;
        }
        if (currentActionScreenManager.currentActionConfig?.defaultNoLot) {
          setDefaultNoLot(
            getDefaultNoLotForWarehouse(
              currentActionScreenManager.currentActionConfig
            )
          );
        }
      }

      //set stock location settings if any default ones are detected
      //setResult({...result,Id_WarehouseLocation:Id_WarehouseLocation,Id_Warehouse:Id_Warehouse,Id_Lot:Id_Lot});
      if (Id_WarehouseLocation !== 0) {
        dispatchActionScreenProduceQuantityProductStore({
          type: "SET_WAREHOUSE_LOCATION",
          payload: {
            Id_WarehouseLocation: Id_WarehouseLocation,
            label: warehouseLocationLabel,
          },
        });
      }

      if (Id_Warehouse !== 0) {
        dispatchActionScreenProduceQuantityProductStore({
          type: "SET_WAREHOUSE",
          payload: { Id_Warehouse: Id_Warehouse, label: warehouseLabel },
        });
      }

      if (Id_Lot !== 0) {
        dispatchActionScreenProduceQuantityProductStore({
          type: "SET_LOT",
          payload: { Id_Lot: Id_Lot, label: lotLabel, code: lotCode },
        });
      }
      
      setInitiallyDefinedLot({Id:Id_Lot, lotCode:lotCode});

      if(prodFinalLotExpDate){
        dispatchActionScreenProduceQuantityProductStore({
          type: "SET_EXPIRATION_DATE",
          payload: { expirationDate: prodFinalLotExpDate },
        });
      }

      if (
      currentActionScreenManager?.currentActionConfig?.defaultProductionQtyFieldByProductivityScale === 1
      ) {
        let qtyScaleProductivity = productivityScale.getProductivityScale();
          //productionOrderSelectionStore.productionRecord.scaleProductivity;

        dispatchActionScreenProduceQuantityProductStore({
          type: "SET_QUANTITY",
          payload: qtyScaleProductivity,
        });
        setRemountKeypadIndex(remountKeypadIndex + 1);
      } else if (
        currentActionScreenManager?.currentActionConfig
          ?.defaultProductionQtyFieldValue
      ) {
        dispatchActionScreenProduceQuantityProductStore({
          type: "SET_QUANTITY",
          payload:
            currentActionScreenManager?.currentActionConfig
              ?.defaultProductionQtyFieldValue,
        });
        setRemountKeypadIndex(remountKeypadIndex + 1);
      }

      if(currentActionScreenManager?.currentActionConfig?.autoConfirm === 1){
        let qtyToFill = currentActionScreenManager?.currentActionConfig?.defaultProductionQtyFieldValue;

        if( currentActionScreenManager?.currentActionConfig?.defaultProductionQtyFieldByProductivityScale === 1){
          qtyToFill = productivityScale.getProductivityScale();
        }

        let hasErrors = false;
        if(qtyToFill <= 0){
          feedbackService.notifyToast(t('auto.confirmation.requires.default.qty.config'),'error');
          hasErrors = true;
        }


        if(hasErrors){
          props.callbackOnClose();
        }else{
       

          stepConfirm(qtyToFill);
        }
      }
    
      //---------------------------
      setDidMount(true);
    }
  }, [
    didMount,
    productionOrderSelectionStore,
    currentActionScreenManager.currentActionConfig,
    result,
    remountKeypadIndex,
  ]);

  console.log("mystt", screenState);
  if (currentStep === "STOCK_LOCATION_SET") {
    return (
      <>
      {isLoading && (
        <HxfOverlayLoading/>
      )}
        <div key={indexkey + "_forceRerender_w2"} className={styles.container}>
          <ActionScreenTopBar
            callbackOnClose={props.callbackOnClose}
            title={t("production.stock.location")}
          />

          <div className={styles.contentProductionLocation}>
            <div className={styles.label}>
              {t("location.where.beeig.stored")}
            </div>
            <div>
              {currentActionScreenManager.currentActionConfig
                ?.allowManualWarehouseLocationSet === 1 && (
                <div className={styles.productionTgtStock}>
                  <HxfSelectorField
                    fullWidth
                    error={warehouseLocationError}
                    callbackCacheSearchResults={
                      callbackAddWarehouseLocationCacheItems
                    }
                    onChange={(evt, val) => {

                      let allowAutoClearingLot = true;
                      if(hasDefinedProdLotFinal && currentActionScreenManager.currentActionConfig?.default_productions_lotSearchability !== 1){
                        //if lot input is not "search" type, and has a pop defined lot (inherit)
                        allowAutoClearingLot = false;
                      }

                      if (
                        val === null ||
                        screenState.Id_WarehouseLocation !== val.value
                      ) {
                        if (val === null) {
                          dispatchActionScreenProduceQuantityProductStore({
                            type: "RESET_WAREHOUSE_LOCATION",
                          });
                          setWarehouseSearchFilters({});
                          setLotSearchFilters({});
                          setClearedWarehouseLocation(true);
                        } else {
                          let label = val.label;
                          let value = val.value;
                          dispatchActionScreenProduceQuantityProductStore({
                            type: "SET_WAREHOUSE_LOCATION",
                            payload: {
                              Id_WarehouseLocation: val.value,
                              label: label,
                            },
                          });

                          setWarehouseSearchFilters({
                            Id_WarehouseLocation: val.value,
                          });
                          setLotSearchFilters({
                            Id_WarehouseLocation: val.value,
                          });
                        }

                        dispatchActionScreenProduceQuantityProductStore({
                          type: "RESET_WAREHOUSE",
                        });
                        if (useDefaultNoLotIfNoLotSelected) {
                          setDefaultNoLot(null);
                        }
                        setClearedWarehouse(true);
                        if(allowAutoClearingLot){
                          dispatchActionScreenProduceQuantityProductStore({
                            type: "RESET_LOT",
                          });
                          setClearedLot(true);
                        }
                        forceRerender();
                      }
                    }}
                    value={getWarehouseLocationState()}
                    listJsonFunction={
                      warehouseLocationsController()
                        .getWarehouseLocationsListJson
                    }
                    uniqueId={"whLoc_op_tgt_stock"}
                    labelPlaceholder={t("select.a.warehouselocation")}
                  />
                </div>
              )}

              {currentActionScreenManager.currentActionConfig
                ?.allowManualWarehouseSet === 1 && (
                <div className={styles.productionTgtStock}>
                  <HxfSelectorField
                    searchingFilterKey={"searchBarFilter"}
                    error={warehouseError}
                    disableInternalFiltering={true}
                    // callbackCacheSearchResults={callbackAddWarehouseCacheItems}
                    onChange={(evt, val) => {
                      debugger;
                      if(warehouseError){
                        setWarehouseError(false);
                      }
                      let allowAutoClearingLot = true;
                      if(hasDefinedProdLotFinal && currentActionScreenManager.currentActionConfig?.default_productions_lotSearchability !== 1){
                        //if lot input is not "search" type, and has a pop defined lot (inherit)
                        allowAutoClearingLot = false;
                      }
                      if (
                        val === null ||
                        screenState.Id_Warehouse !== val.value
                      ) {
                        if (val === null) {
                          dispatchActionScreenProduceQuantityProductStore({
                            type: "RESET_WAREHOUSE",
                          });

                          setClearedWarehouse(true);
                          setLotSearchFilters({});
                          if (useDefaultNoLotIfNoLotSelected) {
                            setDefaultNoLot(null);
                          }
                        } else {
                          let label = val.label;
                          let value = val.value;
                          dispatchActionScreenProduceQuantityProductStore({
                            type: "SET_WAREHOUSE",
                            payload: { Id_Warehouse: val.value, label: label },
                          });
                          setLotSearchFilters({ Id_Warehouse: val.value });
                          let warehouseCachedObj = val?.fetchedData;/*getWarehouseCacheItem(
                            val.value
                          );*/

                          if (warehouseCachedObj === null) {
                            //throw error
                          } else {
                            if (
                              warehouseCachedObj.warehouseLocationData !== null
                            ) {
                              let warehouseLocationJsonObj = JSON.parse(
                                warehouseCachedObj.warehouseLocationData
                              );
                              //set associated wwarehouse location
                              dispatchActionScreenProduceQuantityProductStore({
                                type: "SET_WAREHOUSE_LOCATION",
                                payload: {
                                  Id_WarehouseLocation: parseInt(
                                    warehouseLocationJsonObj.Id_WarehouseLocation
                                  ),
                                  label:
                                    "(" +
                                    warehouseLocationJsonObj.code +
                                    ") " +
                                    warehouseLocationJsonObj.name,
                                },
                              });
                              setWarehouseSearchFilters({
                                Id_WarehouseLocation:
                                  warehouseLocationJsonObj.Id_WarehouseLocation,
                              });
                            }

                            if (useDefaultNoLotIfNoLotSelected) {
                              setDefaultNoLot(
                                getDefaultNoLotForWarehouse(warehouseCachedObj)
                              );
                            }
                          }
                        }

                        if(allowAutoClearingLot){
                          setClearedLot(true);
                          dispatchActionScreenProduceQuantityProductStore({
                            type: "RESET_LOT",
                          });
                        }
    

                        forceRerender();
                      }
                    }}
                    value={getWarehouseState()}
                    listJsonFunction={
                      warehousesController().getWarehousesListJson
                    }
                    listJsonOptions={{
                      includeWarehouseLocationData: "true",
                      includeLotDefaultNoLot: "true",
                      ...warehouseSearchFilters,
                    }}
                    uniqueId={"wh_op_tgt_stock"}
                    labelPlaceholder={t("select.a.warehouse")}
                  />
                </div>
              )}
              {currentActionScreenManager.currentActionConfig
                ?.allowManualLotSet === 1 && (
                  <>
                <div className={styles.productionTgtStock}>
                  <div>
                    {currentActionScreenManager.currentActionConfig?.default_productions_lotSearchability === 1 ? (
                      <HxfSelectorField
                        disabled={
                          !(
                            screenState?.Id_Warehouse !== null &&
                            screenState?.Id_Warehouse !== 0
                          )
                        }
                        disableInternalFiltering={true}
                        searchingFilterKey={"searchingCodeName"}
                        allowFreeTypePersist={true}
                        onFieldTyping={(evt) => {
                          console.log("Typing: ", evt.target.value);
                        }}
                        allowCreation={false}
                        error={lotError}
                        callbackCacheSearchResults={callbackAddLotCacheItems}
                        onChange={(evt, val, shouldRerender) => {
                          console.log("ON CHANGE: ", val);
                          if(lotError){
                            setLotError(false);
                          }
                          if (
                            typeof val === "string" ||
                            (val?.value === null && val?.inputValue !== null)
                          ) {
                            let newLotName = "";
                            if (typeof val === "string") {
                              newLotName = val;
                            }
                            if (val?.inputValue !== undefined) {
                              newLotName = val.inputValue;
                            }

                            //creating lot directly
                            dispatchActionScreenProduceQuantityProductStore({
                              type: "SET_LOT",
                              payload: { Id_Lot: "new", label: newLotName },
                            });
                          } else {
                            if (
                              val === null ||
                              screenState.Id_Lot !== val.value
                            ) {
                              if (val === null) {
                                dispatchActionScreenProduceQuantityProductStore(
                                  {
                                    type: "RESET_LOT",
                                  }
                                );
                                setClearedLot(true);
                              } else {
                                let label = val.label;
                                let value = val.value;
                                dispatchActionScreenProduceQuantityProductStore(
                                  {
                                    type: "SET_LOT",
                                    payload: {
                                      Id_Lot: val.value,
                                      label: label,
                                    },
                                  }
                                );
                                //todo get Warehouse and Id_Warehouse  from this elmement cache and set, get Warehouse Location and Id_Warehouse location from cache and set
                              }
                              if (
                                shouldRerender === undefined ||
                                shouldRerender
                              ) {
                                console.log("triggering rerender");
                                forceRerender();
                              }
                            }
                          }
                        }}
                        value={getLotState()}
                        listJsonFunction={lotsController().getLotsListJson}
                        listJsonOptions={{
                          includeWarehouseData: "true",
                          includeWarehouseLocationData: "true",
                          ...warehouseSearchFilters,
                          ...lotSearchFilters,
                        }}
                        uniqueId={"lot_op_tgt_stock"}
                        labelPlaceholder={t("select.a.lot")}
                      />
                    ) : (
                      <TextField
                        value={getInputLotState()}
                        fullWidth
                        onChange={(evt: any) => {
                          if(lotError){
                            setLotError(false);
                          }
                          let newLot = evt.target.value;
                          
                          if (newLot !== "") {
                            //creating lot directly
                            dispatchActionScreenProduceQuantityProductStore({
                              type: "SET_LOT",
                              payload: { Id_Lot: "new", label: newLot,code: newLot },
                            });
                          } else {
                            dispatchActionScreenProduceQuantityProductStore({
                              type: "RESET_LOT",
                            });
                            setClearedLot(true);
                          }
                        }}
                        error={lotError}
                        label={t("type.lot")}
                      />
                    )}
                  </div>
                  <div>
                    {defaultNoLot ? (
                      <>
                        <small>({t("leave.empty.use.default")})</small>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                    {/* date picker goes here*/}
                    {currentActionScreenManager.currentActionConfig?.allowManualLotExpSet === 1 && (
                    <div className={styles.productionTgtStock}>
										<HxfDatePicker
											fullWidth
											label={t("expiration.date")}
											inputVariant="outlined"
                      
											value={getExpirationDateState()}
											onChange={(evt:any) =>{
                        if(lotExpirationError){
                          setLotExpirationError(false);
                        }
												let nDate = evt;
											
												if(!evt){
													nDate = null;
												}else{
													nDate.setHours(23,59,59,0);
												}

												let dateTest = new Date();
												//setFormData({...formData, expirationDate: nDate});
												//setFormModified(true);

                        let newDateStr = nDate ? nDate.toISOString() : null;
                        dispatchActionScreenProduceQuantityProductStore({
                          type: "SET_EXPIRATION_DATE",
                          payload: { expirationDate:newDateStr },
                        });
											}}
											error={lotExpirationError}
										
										/>
                    </div>
                    )}

                </>
              )}
            </div>

          </div>
          <div className={confirmationStyles.confirmationContainer}>
              <IonButton
                className={confirmationStyles.confirmButton}
                onClick={() => {
                  
                  stepConfirm(null);
                }}
              >
                {t("confirm")}
              </IonButton>
            </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div key={indexkey + "_forceRerender"} className={styles.container}>
        <ActionScreenTopBar
          callbackOnClose={props.callbackOnClose}
          title={t('produced.quantity')}
        />

        <div className={styles.content}>
          <div className={styles.label}>
            

            <Trans i18nKey="type.amount.of.produced" values={{productname:productionOrderSelectionStore.product.name}}>
                Type in the <b>OK</b> quantity of <b>{productionOrderSelectionStore.product.name}</b>
           </Trans>
          </div>
          <div
            className={styles.keyPad}
            key={"produceQtyKeyPad_" + remountKeypadIndex}
          >
            <HxfDigitKeyboard
              disallowEdittingQuantity={currentActionScreenManager?.currentActionConfig?.disallowEditQuantity === 1}
              uomLabel={getProperUomLabel()}
              defaultValue={screenState.quantity}
              onChange={(res) => {
                console.log("CHANGING::: ", res);

                if (askBomScreenState?.mapConsumptions?.length > 0) {
                  //resetting store allows useEffect recalculation at askBomConsumption
                  dispatchActionScreenAskBomConsumptionsStore({
                    type: "RESET_STORE",
                  });
                }
                dispatchActionScreenProduceQuantityProductStore({
                  type: "SET_QUANTITY",
                  payload: res,
                });
              }}
              showErrorInput={showErrorInput}
              onConfirmation={(res) => {
                //confirmQuantity(res);
                stepConfirm(res);
              }}
              enableButtonOnMount={true}
            />
          </div>
        </div>
      </div>
    </>
  );
}
export default ActionScreenProduceQuantityProduct;
