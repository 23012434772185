import useHxfTranslation from "../../../../../../../../../barrel/hooks/useHxfTranslation";
import MathsService from "../../../../../../../../../barrel/services/mathsservice";
import HxfIonPopup from "../../../../../../../../CustomElements/ProductionUI/HxfIonPopup/HxfIonPopup";
import styles from "./PopupOperationProductActionInfo.module.scss";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ClearIcon from '@mui/icons-material/Clear';
import HxfNumberFormat from "../../../../../../../../../barrel/services/hxfNumberFormat";
interface IProps{
    resultOperationDataFetch?:any;
    onClose?:any;
}
function PopupOperationProductActionInfo(props:IProps){
    const {t} = useHxfTranslation();


  const getOPAMFVoffset = (idealValue:any, offset:any) => {

    let resValue = null;
    if(idealValue !== undefined && offset !== undefined){
    
      idealValue =  HxfNumberFormat().val(parseFloat(idealValue)); // MathsService().floatConvert(parseFloat(idealValue),7);
      offset = HxfNumberFormat().val(parseFloat(offset)); //MathsService().floatConvert(parseFloat(offset),7);
      resValue = idealValue + offset;
      resValue = HxfNumberFormat().val(resValue);
    }
    if(resValue === null){
      return (<></>)
    }
    return (<>{MathsService().floatConvert(resValue,7)}</>)
  }
  const showOPADataMatchedFixedVars = (operationProductAction:any, resultOperationDataFetch:any) => {

    let mfvs = operationProductAction?.action_config?.matchingFixedVariables;
    let arrayFvs = resultOperationDataFetch?.extra?.arrayFixedVariables;
    let mapFv_data:any = {};
    if(arrayFvs){
      for(let i = 0; i<arrayFvs.length; i++){
        let idFv = arrayFvs[i]?.Id;
        mapFv_data[parseInt(idFv)] = arrayFvs[i];
      }
    }

    
    return (
      <div>
        <div className={styles.opaTitle}>{t('matched.fixed.variables')}</div>
        <div className={styles.opaMFVSList}>
          {mfvs.map((mfixedvar:any, indexmfv:any) => (
            <div key={operationProductAction.Id + "_mfv_" + indexmfv}>
              <div className={styles.opaMFVContainer}>
                <div className={styles.fvTitle}>{mapFv_data?.[parseInt(mfixedvar.Id_FixedVariable)]?.name}</div>
                {mapFv_data?.[parseInt(mfixedvar.Id_FixedVariable)]?.varType === "numeric" && (
                  <div className={styles.limitsMFVS}>
                    <div className={styles.mfvMinVal}>{getOPAMFVoffset(mapFv_data?.[parseInt(mfixedvar.Id_FixedVariable)]?.value, mfixedvar?.negativeOffset ? (parseFloat(mfixedvar.negativeOffset)*-1) : 0)}</div>
                    <div className={styles.mfvCentral}>{mapFv_data?.[parseInt(mfixedvar.Id_FixedVariable)]?.value}</div>
                    <div className={styles.mfvMaxVal}>{getOPAMFVoffset(mapFv_data?.[parseInt(mfixedvar.Id_FixedVariable)]?.value, mfixedvar?.positiveOffset ? (parseFloat(mfixedvar.positiveOffset)) : 0)}</div>
                  </div>
                )}
              {mapFv_data?.[parseInt(mfixedvar.Id_FixedVariable)]?.varType === "checkbox" && (
                  <div className={styles.mfvIconCheck}>
                    {mapFv_data?.[parseInt(mfixedvar.Id_FixedVariable)]?.value === "true" || mapFv_data?.[parseInt(mfixedvar.Id_FixedVariable)]?.value === true ? (
                        <div><CheckBoxIcon/></div>
                    ): (<div><ClearIcon/></div>)}
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>

      </div>
    )
  }

  const getRelevantOperationActionsData = (resultOperationDataFetch:any) => {

   
    let operationProductActions = resultOperationDataFetch?.data?.operationProductActions;
    if(operationProductActions){
      
      return (
        <>{operationProductActions.map((objOperationProductAction:any, indexOpa:any) => (
          <div key={"idxOpea_" + indexOpa}>
            {
              objOperationProductAction?.code === "MATCH_FIXED_VARIABLES" &&  objOperationProductAction?.action_config?.showOnPreSelect === 1 && (
                <div>
                  {showOPADataMatchedFixedVars(objOperationProductAction,resultOperationDataFetch)}
                </div>
              )
            }
          </div>
        ))}</>
      )
    }
  
    return (<></>)
  }

    return (
        <HxfIonPopup title={t('operation')} onClose={() => {
            props.onClose();
          }} content={(
            <div>
              <div className={styles.topBar}>{props?.resultOperationDataFetch?.data?.code}</div>
              <div style={{textAlign:'center',marginTop:10}}><b>{t('actions.info')}</b></div>
              {getRelevantOperationActionsData(props.resultOperationDataFetch)}
         
            </div>
          )}/>
    )
}

export default PopupOperationProductActionInfo;